<template>
    <div class="padding-container">
        <div class="content-load" v-if="isLoadData"></div>
        <div id="loader" v-if="isLoadData"></div>
        <div
            class="text-black size-16 text-gray-dashboard fw-semibold mb-4 cursor-pointer"
            @click="$router.go(-1)"
        >
            <i class="fa fa-angle-left me-2"></i>
            Kembali
        </div>
        <div class="box row" v-if="data">
            <div class="col-md-2">
                <img
                    v-if="data.agent_picture"
                    class="user-avatar-sidebar object-fit-cover"
                    :src="data.agent_picture"
                    alt="agent picture"
                />
                <div
                    v-else
                    class="user-avatar-sidebar object-fit-cover"
                    :style="{
                        'background-image':
                            'url(' + require('../../assets/user.jpg') + ')',
                    }"
                ></div>
            </div>
            <div class="col-md-10">
                <div class="row">
                    <div class="col-md-6 d-flex">
                        <h5 class="fw-bold">Detail Akun Sales Agent</h5>
                        <span
                            :class="[
                                'tag radius px-4 capitalize ms-3 d-flex flex-column justify-content-center',
                                {
                                    panding: data.status === 'pending',
                                    reject: data.status === 'reject',
                                    resubmit: data.status === 'resubmit',
                                    banned: data.status === 'banned',
                                    'bg-success text-white':
                                        data.status === 'accept',
                                },
                            ]"
                        >
                            {{ data.status }}
                        </span>
                    </div>
                    <div
                        class="col-md-6 d-flex justify-content-end"
                        v-if="
                            data.status !== 'accept' && data.status !== 'banned'
                        "
                    >
                        <button
                            type="button"
                            class="btn btn-outline-red btn-md px-4 me-2"
                            @click="modalConfirmReject = true"
                            :disabled="data.status === 'reject'"
                        >
                            Reject Agent
                        </button>
                        <button
                            type="button"
                            class="btn btn-green btn-md px-4"
                            @click="modalConfirmAccept = true"
                            :disabled="data.status === 'reject'"
                        >
                            Accept Agent
                        </button>
                    </div>
                    <div class="col-md-12">
                        <p class="fw-bold">Informasi Agent</p>
                    </div>
                    <div class="col-6 mb-3">
                        <label class="fw-semibold"> Tanggal Registrasi </label>
                        <div class="form-box bg-transparent mt-2">
                            {{ data.register_at ? data.register_at : "-" }}
                        </div>
                    </div>
                    <div class="col-6 mb-3">
                        <label class="fw-semibold"> Nama Koordinator </label>
                        <div class="form-box bg-transparent mt-2">
                            {{ data.coor_name ? data.coor_name : "-" }}
                        </div>
                    </div>
                    <div class="col-6 mb-3">
                        <label class="fw-semibold"> Nama Lengkap </label>
                        <div class="form-box bg-transparent mt-2">
                            {{ data.agent_name ? data.agent_name : "-" }}
                        </div>
                    </div>
                    <div class="col-6 mb-3">
                        <label class="fw-semibold"> Email </label>
                        <div class="form-box bg-transparent mt-2">
                            {{ data.email ? data.email : "-" }}
                        </div>
                    </div>
                    <div class="col-6 mb-3">
                        <label class="fw-semibold"> Nomor Telepon </label>
                        <div class="form-box bg-transparent mt-2">
                            {{ data.phone ? data.phone : "-" }}
                        </div>
                    </div>
                    <hr class="my-4" />
                    <div class="col-md-12">
                        <p class="fw-bold">Alamat Domisili</p>
                    </div>
                    <div class="col-6 mb-3">
                        <label class="fw-semibold"> Provinsi </label>
                        <div class="form-box bg-transparent mt-2">
                            {{
                                data.domicile.province
                                    ? data.domicile.province
                                    : "-"
                            }}
                        </div>
                    </div>
                    <div class="col-6 mb-3">
                        <label class="fw-semibold"> Kota/Kabupaten </label>
                        <div class="form-box bg-transparent mt-2">
                            {{
                                data.domicile.regency
                                    ? data.domicile.regency
                                    : "-"
                            }}
                        </div>
                    </div>
                    <div class="col-6 mb-3">
                        <label class="fw-semibold"> Kecamatan </label>
                        <div class="form-box bg-transparent mt-2">
                            {{
                                data.domicile.district
                                    ? data.domicile.district
                                    : "-"
                            }}
                        </div>
                    </div>
                    <div class="col-6 mb-3">
                        <label class="fw-semibold"> Kelurahan </label>
                        <div class="form-box bg-transparent mt-2">
                            {{
                                data.domicile.subdistrict
                                    ? data.domicile.subdistrict
                                    : "-"
                            }}
                        </div>
                    </div>
                    <div class="col-12 mb-3">
                        <label class="fw-semibold"> Detail Alamat </label>
                        <div class="form-box bg-transparent mt-2">
                            {{
                                data.domicile.address
                                    ? data.domicile.address
                                    : "-"
                            }}
                        </div>
                    </div>
                    <hr class="my-4" />
                    <div class="col-md-12">
                        <p class="fw-bold">Informasi Nomor Rekening</p>
                    </div>
                    <div class="col-md-4 mb-3">
                        <label class="fw-semibold"> Rekening Bank </label>
                        <div class="form-box bg-transparent mt-2">
                            {{
                                data.bank_account.account_number
                                    ? data.bank_account.account_number
                                    : "-"
                            }}
                        </div>
                        <div
                            class="d-flex gap-2 text-reject mt-3"
                            v-if="
                                isFieldRejected('account_number') &&
                                data.status !== 'pending' &&
                                data.status !== 'resubmit' &&
                                data.status !== 'accept'
                            "
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="22"
                                height="22"
                                viewBox="0 0 22 22"
                                fill="none"
                            >
                                <circle
                                    cx="11"
                                    cy="11"
                                    r="9.75"
                                    stroke="#E60013"
                                    stroke-width="1.5"
                                />
                                <circle
                                    cx="11"
                                    cy="8"
                                    r="0.5"
                                    fill="#E60013"
                                    stroke="#E60013"
                                />
                                <path
                                    d="M11 10.5V15"
                                    stroke="#E60013"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                            Dokumen ini ditolak
                        </div>
                    </div>
                    <div class="col-md-4 mb-3">
                        <label class="fw-semibold">
                            Nama Pemilik Rekening
                        </label>
                        <div class="form-box bg-transparent mt-2">
                            {{
                                data.bank_account.account_name
                                    ? data.bank_account.account_name
                                    : "-"
                            }}
                        </div>
                        <div
                            class="d-flex gap-2 text-reject mt-3"
                            v-if="
                                isFieldRejected('account_name') &&
                                data.status !== 'pending' &&
                                data.status !== 'resubmit' &&
                                data.status !== 'accept'
                            "
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="22"
                                height="22"
                                viewBox="0 0 22 22"
                                fill="none"
                            >
                                <circle
                                    cx="11"
                                    cy="11"
                                    r="9.75"
                                    stroke="#E60013"
                                    stroke-width="1.5"
                                />
                                <circle
                                    cx="11"
                                    cy="8"
                                    r="0.5"
                                    fill="#E60013"
                                    stroke="#E60013"
                                />
                                <path
                                    d="M11 10.5V15"
                                    stroke="#E60013"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                            Dokumen ini ditolak
                        </div>
                    </div>
                    <div class="col-md-4 mb-3">
                        <label class="fw-semibold"> Nama Bank </label>
                        <div class="form-box bg-transparent mt-2">
                            {{
                                data.bank_account.bank_name
                                    ? data.bank_account.bank_name
                                    : "-"
                            }}
                        </div>
                        <div
                            class="d-flex gap-2 text-reject mt-3"
                            v-if="
                                isFieldRejected('bank_id') &&
                                data.status !== 'pending' &&
                                data.status !== 'resubmit' &&
                                data.status !== 'accept'
                            "
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="22"
                                height="22"
                                viewBox="0 0 22 22"
                                fill="none"
                            >
                                <circle
                                    cx="11"
                                    cy="11"
                                    r="9.75"
                                    stroke="#E60013"
                                    stroke-width="1.5"
                                />
                                <circle
                                    cx="11"
                                    cy="8"
                                    r="0.5"
                                    fill="#E60013"
                                    stroke="#E60013"
                                />
                                <path
                                    d="M11 10.5V15"
                                    stroke="#E60013"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                            Dokumen ini ditolak
                        </div>
                    </div>
                    <hr class="my-4" />
                    <div class="col-md-4 mt-3">
                        <label class="fw-semibold">Foto Agent</label>
                        <div class="mt-2">
                            <viewer
                                :options="options"
                                v-if="data.agent_picture"
                            >
                                <img
                                    class="image-cover"
                                    :src="data.agent_picture"
                                />
                            </viewer>
                            <div
                                v-else
                                class="image-default"
                                :style="{
                                    'background-image':
                                        'url(' +
                                        require('../../assets/empty.png') +
                                        ')',
                                }"
                            ></div>
                        </div>
                        <div
                            class="d-flex gap-2 text-reject mt-3"
                            v-if="
                                isFieldRejected('agent_picture') &&
                                data.status !== 'pending' &&
                                data.status !== 'resubmit' &&
                                data.status !== 'accept'
                            "
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="22"
                                height="22"
                                viewBox="0 0 22 22"
                                fill="none"
                            >
                                <circle
                                    cx="11"
                                    cy="11"
                                    r="9.75"
                                    stroke="#E60013"
                                    stroke-width="1.5"
                                />
                                <circle
                                    cx="11"
                                    cy="8"
                                    r="0.5"
                                    fill="#E60013"
                                    stroke="#E60013"
                                />
                                <path
                                    d="M11 10.5V15"
                                    stroke="#E60013"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                            Dokumen ini ditolak
                        </div>
                    </div>
                    <div class="col-md-4 mt-3">
                        <label class="fw-semibold">Foto KTP</label>
                        <div class="mt-2">
                            <viewer
                                :options="options"
                                v-if="data.identity_picture"
                            >
                                <img
                                    class="image-cover"
                                    :src="data.identity_picture"
                                />
                            </viewer>
                            <div
                                v-else
                                class="image-default"
                                :style="{
                                    'background-image':
                                        'url(' +
                                        require('../../assets/empty.png') +
                                        ')',
                                }"
                            ></div>
                        </div>
                        <div
                            class="d-flex gap-2 text-reject mt-3"
                            v-if="
                                isFieldRejected('identity_picture') &&
                                data.status !== 'pending' &&
                                data.status !== 'resubmit' &&
                                data.status !== 'accept'
                            "
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="22"
                                height="22"
                                viewBox="0 0 22 22"
                                fill="none"
                            >
                                <circle
                                    cx="11"
                                    cy="11"
                                    r="9.75"
                                    stroke="#E60013"
                                    stroke-width="1.5"
                                />
                                <circle
                                    cx="11"
                                    cy="8"
                                    r="0.5"
                                    fill="#E60013"
                                    stroke="#E60013"
                                />
                                <path
                                    d="M11 10.5V15"
                                    stroke="#E60013"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                            Dokumen ini ditolak
                        </div>
                    </div>
                    <hr
                        class="my-4"
                        v-if="
                            data.status !== 'pending' &&
                            data.history_rejects.length !== 0
                        "
                    />
                    <div
                        class="col-md-12"
                        v-if="
                            data.status !== 'pending' &&
                            data.history_rejects.length !== 0
                        "
                    >
                        <p class="fw-bold">Riwayat Penolakan</p>
                        <ul class="timeline timeline-split mt-4">
                            <li
                                class="timeline-item d-flex"
                                v-for="i in data.history_rejects.length"
                                :set="
                                    (reject =
                                        data.history_rejects[
                                            data.history_rejects.length - i
                                        ])
                                "
                                :key="i"
                            >
                                <div
                                    class="timeline-marker"
                                    v-bind:class="{
                                        'active-red': i == 1,
                                    }"
                                ></div>
                                <div class="timeline-content w-100">
                                    <div
                                        class="timeline-title"
                                        v-bind:class="{
                                            'text-dark': i == 1,
                                        }"
                                    >
                                        Penolakan
                                        <template
                                            v-if="
                                                data.history_rejects.length == 3
                                            "
                                        >
                                            <template v-if="i == 1"
                                                >Ketiga</template
                                            >
                                            <template v-if="i == 2"
                                                >Kedua</template
                                            >
                                            <template v-if="i == 3"
                                                >Pertama</template
                                            >
                                        </template>
                                        <template
                                            v-if="
                                                data.history_rejects.length == 2
                                            "
                                        >
                                            <template v-if="i == 1"
                                                >Kedua</template
                                            >
                                            <template v-if="i == 2"
                                                >Pertama</template
                                            >
                                        </template>
                                        <template
                                            v-if="
                                                data.history_rejects.length == 1
                                            "
                                        >
                                            <template v-if="i == 1"
                                                >Pertama</template
                                            >
                                        </template>
                                        -
                                        <span
                                            v-bind:class="{
                                                'text-danger': i == 1,
                                            }"
                                        >
                                            {{ reject.rejected_at }}
                                        </span>
                                    </div>
                                    <div class="mt-3">
                                        <small
                                            class="form-box bg-transparent d-block"
                                            v-bind:class="{
                                                reject: i == 1,
                                                gray: i > 1,
                                            }"
                                        >
                                            {{ reject.reason }}
                                        </small>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <!-- MODAL -->
        <div class="modal-vue" v-if="modalConfirmReject">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="d-flex bd-highlight align-items-lg-center">
                    <div class="flex-grow-1 bd-highlight">
                        <div class="size-18 fw-bold text-center">
                            <img
                                src="../../assets/info-yellow.png"
                                width="70"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
                <div class="mt-4">
                    <div class="size-18 fw-bold text-center">
                        Pendaftaran Sales Agent Ini Akan Ditolak
                    </div>
                    <div class="text-gray-dashboard text-center">
                        Mohon lengkapi checklist sheet dan berikan alasan
                        penolakan
                    </div>
                    <div class="mt-3 row">
                        <div class="col-md-6 mt-4">
                            <button
                                class="btn btn-outline-red w-100 btn-lg"
                                @click="modalConfirmReject = false"
                            >
                                Batal
                            </button>
                        </div>
                        <div class="col-md-6 mt-4">
                            <router-link
                                :to="`/register-sales-affiliate/reject/${$route.params.id}`"
                                class="btn btn-green w-100 btn-lg"
                            >
                                Ok
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalConfirmAccept">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="d-flex bd-highlight align-items-lg-center">
                    <div class="flex-grow-1 bd-highlight">
                        <div class="size-18 fw-bold text-center">
                            <img
                                src="../../assets/info-yellow.png"
                                width="70"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
                <div class="mt-4">
                    <div class="size-18 fw-bold text-center">
                        Apakah Kamu Yakin Ingin Menyetujui Registrasi Sales
                        Agent Ini?
                    </div>
                    <div class="mt-3 row">
                        <div class="col-md-6 mt-4">
                            <button
                                class="btn btn-outline-red w-100 btn-lg"
                                @click="modalConfirmAccept = false"
                            >
                                Batal
                            </button>
                        </div>
                        <div class="col-md-6 mt-4">
                            <button
                                class="btn btn-green w-100 btn-lg"
                                @click="storeAccept()"
                            >
                                <span
                                    v-if="isAcceptLoading"
                                    class="spinner-border spinner-border-sm me-2"
                                ></span>
                                Iya
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalSuccessAccept">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="d-flex bd-highlight align-items-lg-center">
                    <div class="flex-grow-1 bd-highlight">
                        <div class="size-18 fw-bold text-center">
                            <img
                                src="../../assets/check-new.png"
                                width="70"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
                <div class="mt-4">
                    <div class="size-18 fw-bold text-center">
                        Registrasi Sales Agent Ini Berhasil Disetujui
                    </div>
                    <div class="mt-3 row">
                        <div class="col-md-12 mt-4">
                            <router-link
                                class="btn btn-green w-100 btn-lg"
                                to="/register-sales-affiliate"
                            >
                                OK
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import "viewerjs/dist/viewer.css";
import { component as Viewer } from "v-viewer";

export default {
    components: {
        Viewer,
    },
    data() {
        return {
            options: {
                title: false,
                toolbar: false,
                navbar: false,
                button: false,
            },
            modalConfirmReject: false,
            modalConfirmAccept: false,
            modalSuccessAccept: false,
            data: null,
            isLoadData: false,
            isAcceptLoading: false,
        };
    },
    created() {
        this.getDetail();
    },
    methods: {
        isFieldRejected(field) {
            return (
                this.data &&
                this.data.last_reject &&
                Array.isArray(this.data.last_reject.fields) &&
                this.data.last_reject.fields.includes(field)
            );
        },
        getDetail() {
            this.isLoadData = true;
            const params = {
                id: this.$route.params.id,
            };

            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/sa/admin/agent/detail`,
                {
                    params,
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.data = res.data.data;
                    this.isLoadData = false;
                })
                .catch((err) => {
                    this.isLoadData = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        storeAccept() {
            this.isAcceptLoading = true;
            const data = {
                agent_id: this.$route.params.id,
            };
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/sa/admin/agent/register/accept`,
                data,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then(() => {
                    this.isAcceptLoading = false;
                    this.modalConfirmAccept = false;
                    this.modalSuccessAccept = true;
                })
                .catch((err) => {
                    this.isAcceptLoading = false;
                    this.modalConfirmAccept = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
    },
};
</script>

<style scoped>
.user-avatar-sidebar {
    width: 110px;
    height: 110px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 100px;
    position: relative;
    border: 2px solid #f8f9fa;
}
.image-cover {
    border-radius: 10px;
    border: 2px solid #e2e3e5;
    width: 100%;
    height: 150px;
    object-fit: cover;
    cursor: pointer;
}
.image-default {
    width: 100%;
    height: 150px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 6px;
    border: 2px solid #e2e3e5;
}
.text-reject {
    color: #e60013;
}
</style>
