<template>
    <div class="padding-container">
        <div class="fw-bold">
            <div
                class="text-gray-dashboard size-16 cursor-pointer"
                @click="$router.go(-1)"
            >
                <i class="fa fa-angle-left me-2"></i>
                Back
            </div>
        </div>
        <div class="box mt-4">
            <div class="d-flex align-items-center">
                <h3 class="fw-bold mb-0">Edit Data Prospect</h3>
            </div>
            <div class="row mt-5">
                <div class="col-md-6">
                    <label class="fw-bold mb-2">Full Name</label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Full Name"
                        v-model="req.name"
                    />
                </div>
                <div class="col-md-6 m-mt-1">
                    <label class="fw-bold mb-2">Phone Number</label>
                    <input
                        type="number"
                        class="form-control"
                        placeholder="Phone Number"
                        v-model="req.phone"
                    />
                </div>
                <div class="col-md-6 mt-3 m-mt-1">
                    <label class="fw-bold mb-2">Email</label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Email"
                        v-model="req.email"
                    />
                </div>
                <div class="col-md-6 mt-3 m-mt-1">
                    <label class="fw-bold mb-2"
                        >Company
                        <span style="color: #8e8e94">(Optional)</span></label
                    >
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Company"
                        v-model="req.company"
                    />
                </div>
                <div class="col-md-6 mt-3 m-mt-1">
                    <label class="fw-bold mb-2"
                        >Position
                        <span style="color: #8e8e94">(Optional)</span></label
                    >
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Position"
                        v-model="req.position"
                    />
                </div>
                <div class="col-md-6 mt-3 m-mt-1">
                    <label class="fw-bold mb-2"
                        >Office Number
                        <span style="color: #8e8e94">(Optional)</span></label
                    >
                    <input
                        type="number"
                        class="form-control"
                        placeholder="Office Number"
                        v-model="req.office_number"
                    />
                </div>
                <div class="col-md-12 mt-3 m-mt-1">
                    <label class="fw-bold mb-2"
                        >Address
                        <span style="color: #8e8e94">(Optional)</span></label
                    >
                    <textarea
                        cols="30"
                        rows="4"
                        class="form-control"
                        placeholder="Address"
                        v-model="req.address"
                    ></textarea>
                </div>
                <div class="col-md-6 mt-3 m-mt-1">
                    <label class="fw-bold mb-2"> Data Type </label>
                    <select class="form-control form-select" v-model="req.type">
                        <option value="" disabled>Select Data Type</option>
                        <option value="prospect">Prospect</option>
                        <option value="leads">Leads</option>
                        <option value="partnership">Kemitraan</option>
                        <option value="spam">Spam</option>
                        <option value="not-support">Tidak Tersedia (Undercover)</option>
                        <option value="tracking">Pelacakan Resi</option>
                    </select>
                </div>
                <div class="col-md-12 mt-3 m-mt-1">
                    <label class="fw-bold mb-2"
                        >Tags
                        <span style="color: #8e8e94">(Optional)</span></label
                    >
                    <input-tag
                        v-model="req.tags"
                        class="input-tag mb-3"
                        placeholder="Enter Your Tags"
                    ></input-tag>
                </div>
                <div class="col-md-12">
                    <button
                        class="btn btn-sm px-3 btn-green me-2"
                        :disabled="req.tags.includes(`Troben Cargo`)"
                        @click="addTrawlpackTags()"
                    >
                        Troben Cargo
                    </button>
                    <button
                        class="btn btn-sm px-3 btn-yellow me-2"
                        :disabled="req.tags.includes(`Troben Truck`)"
                        @click="addTrawltruckTags()"
                    >
                        Troben Truck
                    </button>
                    <button
                        class="btn btn-sm px-3 btn-blue"
                        :disabled="req.tags.includes(`Troben Carier`)"
                        @click="addTrawlcarrierTags()"
                    >
                        Troben Carier
                    </button>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-12 m-mt-3">
                    <div class="d-flex">
                        <div class="ms-auto">
                            <a
                                href="/crm/customers/leads"
                                class="btn btn-outline-black btn-lg px-5 me-3"
                                >Cancel</a
                            >
                            <button
                                class="btn btn-green btn-lg px-5 me-3"
                                type="button"
                                @click="updateData()"
                                :disabled="is_update"
                            >
                                <span
                                    class="spinner-border spinner-border-sm"
                                    v-if="is_update"
                                ></span>
                                Update
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- modal -->
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccess"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccess">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/checklis.png"
                        width="80"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">Data Updated!!</h4>
                </div>
                <div class="mt-4">
                    <a
                        :href="
                            '/crm/customers/leads/detail/' + $route.params.id
                        "
                        class="btn btn-green w-100 btn-lg"
                    >
                        Ok
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import InputTag from "vue-input-tag";

export default {
    components: {
        InputTag,
    },
    name: "LeadsEdit",
    data() {
        return {
            modalSuccess: false,
            is_load: false,
            is_add: true,
            req: {
                name: "",
                phone: "",
                email: "",
                company: "",
                position: "",
                office_number: "",
                address: "",
                type: "",
                tags: [],
            },
            is_update: false,
        };
    },
    created() {
        this.getDetailData();
    },
    methods: {
        addTrawlpackTags() {
            this.req.tags.push("Troben Cargo");
        },
        addTrawltruckTags() {
            this.req.tags.push("Troben Truck");
        },
        addTrawlcarrierTags() {
            this.req.tags.push("Troben Carier");
        },
        updateData() {
            this.is_update = true;
            var data = {
                id: this.$route.params.id,
                name: this.req.name,
                phone: this.req.phone,
                email: this.req.email,
                company: this.req.company,
                position: this.req.position,
                office_number: this.req.office_number,
                address: this.req.address,
                tags: this.req.tags,
                type: this.req.type,
            };
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/crm/customer/leads/update`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.is_update = false;
                    this.modalSuccess = true;
                    console.log(res);
                })
                .catch((err) => {
                    this.is_update = false;
                    if (err.response.data.data == null) {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.message,
                        });
                    } else {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.data.message,
                        });
                    }
                });
        },
        getDetailData() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/crm/customer/lead/detail?id=${this.$route.params.id}`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )

                .then((res) => {
                    console.log(res);
                    this.req.name = res.data.data.name;
                    this.req.phone = res.data.data.phone;
                    this.req.email = res.data.data.email;
                    this.req.company = res.data.data.company;
                    this.req.position = res.data.data.position;
                    this.req.office_number = res.data.data.office_number;
                    this.req.address = res.data.data.address;
                    this.req.tags = res.data.data.tags;
                    this.req.type = res.data.data.type;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
};
</script>

<style scoped>
.text-gray-dashboard {
    color: #61616a;
}
</style>
