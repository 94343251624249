<template>
    <div class="padding-container">
        <div class="box mt-4 m-mt-0">
            <h3 class="fw-bold">Daftar Branding Sticker Truck</h3>
            <div class="d-flex mt-4 justify-content-between">
                <div class="d-flex">
                    <div class="me-3">
                        <div class="position-relative">
                            <input
                                type="text"
                                class="form-control form-control-lg pl-search w-search"
                                placeholder="Cari nama driver"
                                v-model="search"
                                @input="changeSearch()"
                            />
                            <span class="search-icon left">
                                <i class="fa fa-search"></i>
                            </span>
                        </div>
                    </div>
                    <div class="m-mt-1 ms-3" @click="toggleFilter()">
                        <button class="btn btn-filter px-4">
                            <img
                                src="../../assets/dashboard/filter.png"
                                class="me-1"
                                width="18"
                                alt=""
                            />
                            Filter
                        </button>
                    </div>
                </div>
                <div class="ms-3 m-mx-0 m-mt-2">
                    <button
                        @click="exportModal()"
                        class="btn btn-green btn-with-search px-4 d-flex align-items-center"
                        :disabled="isExport"
                    >
                        Export
                        <img
                            src="../../assets/download.png"
                            width="13"
                            class="ms-2"
                            alt=""
                        />
                    </button>
                </div>
            </div>
            <div class="box mt-3 position-relative" v-if="showFilter">
                <div class="row">
                    <div class="col-md-4 mt-3 position-relative">
                        <label class="mb-3 fw-bold">Jenis Armada</label>
                        <a
                            href="javascript:void(0)"
                            class="dropdown-toggle text-gray-dashboard"
                            id="statusDropdown"
                            data-bs-toggle="dropdown"
                            data-bs-auto-close="outside"
                            aria-expanded="false"
                        >
                            <div class="radius-select d-flex">
                                <div
                                    class="d-flex align-items-center justify-content-between w-100 gap-4"
                                >
                                    <div class="scroll-select">
                                        <template
                                            v-if="selectedFleet.length === 0"
                                        >
                                            <p class="placeholder-custom">
                                                Pilih jenis armada
                                            </p>
                                        </template>
                                        <template v-else>
                                            {{
                                                selectedFleet
                                                    .map((s) =>
                                                        s.name
                                                            .split("_")
                                                            .join(" ")
                                                    )
                                                    .join(", ")
                                            }}
                                        </template>
                                    </div>
                                    <div class="ms-auto arrow-custom"></div>
                                </div>
                            </div>
                        </a>
                        <ul
                            class="dropdown-menu"
                            aria-labelledby="statusDropdown"
                        >
                            <li
                                v-for="fleet in fleets"
                                :key="fleet.id"
                                class="pt-1 pb-1"
                            >
                                <div
                                    class="form-check form-check-inline cursor-pointer"
                                >
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        :id="fleet.id"
                                        :value="fleet"
                                        v-model="selectedFleet"
                                    />
                                    <label
                                        class="form-check-label"
                                        :for="fleet.id"
                                    >
                                        {{ fleet.name.split("_").join(" ") }}
                                    </label>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="col-md-4 mt-3">
                        <label class="mb-3">Tanggal Pendaftaran</label>
                        <date-picker
                            v-model="registerDate"
                            range
                            type="date"
                            value-type="format"
                            class="w-100"
                            format="DD MMMM YYYY"
                            :clearable="false"
                            placeholder="Pilih tanggal pendaftaran"
                        >
                        </date-picker>
                    </div>
                    <div class="col-md-4 mt-3 position-relative">
                        <label class="mb-3 fw-bold">Status</label>
                        <a
                            href="javascript:void(0)"
                            class="dropdown-toggle text-gray-dashboard"
                            id="statusDropdown"
                            data-bs-toggle="dropdown"
                            data-bs-auto-close="outside"
                            aria-expanded="false"
                        >
                            <div class="radius-select d-flex">
                                <div
                                    class="d-flex align-items-center justify-content-between w-100 gap-4"
                                >
                                    <div class="scroll-select">
                                        <template
                                            v-if="selectedStatus.length === 0"
                                        >
                                            <p class="placeholder-custom">
                                                Pilih status
                                            </p>
                                        </template>
                                        <template v-else>
                                            {{
                                                selectedStatus
                                                    .map((s) =>
                                                        s.name
                                                            .split("_")
                                                            .join(" ")
                                                    )
                                                    .join(", ")
                                            }}
                                        </template>
                                    </div>
                                    <div class="ms-auto arrow-custom"></div>
                                </div>
                            </div>
                        </a>
                        <ul
                            class="dropdown-menu"
                            aria-labelledby="statusDropdown"
                        >
                            <li
                                v-for="status in statuses"
                                :key="status.id"
                                class="pt-1 pb-1"
                            >
                                <div
                                    class="form-check form-check-inline cursor-pointer"
                                >
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        :id="status.id"
                                        :value="status"
                                        v-model="selectedStatus"
                                    />
                                    <label
                                        class="form-check-label"
                                        :for="status.id"
                                    >
                                        {{ status.name.split("_").join(" ") }}
                                    </label>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="col-md-2 mt-3">
                        <button
                            class="btn px-5 w-100"
                            :class="
                                selectedFleet.length === 0
                                    ? 'btn-clear-disabled'
                                    : 'btn-outline-yellow'
                            "
                            @click="resetFilter"
                            :disabled="selectedFleet.length === 0"
                        >
                            Reset
                        </button>
                    </div>
                    <div class="col-md-2 mt-3">
                        <button
                            class="btn px-5 w-100"
                            @click="applyFilter"
                            :class="
                                selectedFleet.length === 0
                                    ? 'btn-filter-disabled'
                                    : 'btn-yellow'
                            "
                            :disabled="selectedFleet.length === 0"
                        >
                            Filter
                        </button>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-between mt-4">
                <p class="mb-0">
                    Menampilkan
                    <b
                        >{{ dataDisplayRange.start }}-{{
                            dataDisplayRange.end
                        }}</b
                    >
                    Data dari <b>{{ dataDisplayRange.total }}</b> Data
                </p>
                <div class="ms-auto">
                    <div class="d-flex align-items-center">
                        <div class="me-2">Rows per page</div>
                        <div>
                            <select
                                class="form-control form-select w-select"
                                v-model="limit"
                                @change="getList()"
                            >
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-4" v-if="is_list_ready">
                <ListLoader />
            </div>
            <div class="table-responsive-custom" v-else>
                <table class="table table-bordered mt-4">
                    <thead class="table-light">
                        <tr>
                            <th class="text-center" scope="col">No</th>
                            <th class="text-center" scope="col">Nama Driver</th>
                            <th class="text-center" scope="col">Kota/Kab.</th>
                            <th class="text-center" scope="col">
                                Jenis Armada
                            </th>
                            <th class="text-center" scope="col">
                                Tanggal Pendaftaran
                            </th>
                            <th class="text-center" scope="col">Status</th>
                            <th class="text-center" scope="col">
                                Reject Count
                            </th>
                            <th class="text-center" scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(data, index) in list.list_data"
                            :key="index"
                        >
                            <td class="text-center">{{ index + 1 }}</td>
                            <td>
                                <div class="d-flex align-items-center">
                                    <a
                                        :href="
                                            `/truck/sticker/detail/` + data.id
                                        "
                                        class="text-link me-2"
                                    >
                                        {{ data.driver_name }}
                                    </a>
                                    <svg
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        data-bs-html="true"
                                        :title="data.pic_name"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                    >
                                        <circle
                                            cx="12"
                                            cy="12"
                                            r="9.75"
                                            fill="#3D8824"
                                            stroke="#3D8824"
                                            stroke-width="1.5"
                                        />
                                        <circle
                                            cx="12"
                                            cy="9"
                                            r="0.5"
                                            fill="white"
                                            stroke="white"
                                        />
                                        <path
                                            d="M12 11.5V16"
                                            stroke="white"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </svg>
                                </div>
                            </td>
                            <td>
                                {{
                                    data.regency_name ? data.regency_name : "-"
                                }}
                            </td>
                            <td class="d-flex justify-content-center">
                                <button
                                    class="btn rounded-pill fleet-type-button"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    data-bs-html="true"
                                    v-bind:title="`<p class='mb-0'>${data.registration_number}</p> <p class='mb-0'>${data.current_km}</p>`"
                                >
                                    {{ data.fleet_type_label }}
                                </button>
                            </td>
                            <td class="text-center">{{ data.created_at }}</td>
                            <td class="text-center">
                                <div
                                    class="tag yellow-cro radius mb-0 pb-0"
                                    v-if="data.status == 'pending'"
                                >
                                    Belum Terverifikasi
                                </div>
                                <div
                                    class="tag red-cro radius mb-0 pb-0"
                                    v-if="data.status == 'reject'"
                                >
                                    Ditolak
                                </div>
                                <div
                                    class="tag red-cro radius mb-0 pb-0"
                                    v-if="data.status == 'banned'"
                                >
                                    Banned
                                </div>
                                <div
                                    class="tag green-cro radius mb-0 pb-0"
                                    v-if="data.status == 'accept'"
                                >
                                    Terverifikasi
                                </div>
                            </td>
                            <td class="text-center">
                                <a
                                    href="javascript:void(0)"
                                    class="text-link"
                                    @click="openModalRejectHistory(data)"
                                    v-if="data.reject_count > 0"
                                >
                                    {{ data.reject_count }}
                                </a>
                                <template v-else>
                                    {{ data.reject_count }}
                                </template>
                            </td>
                            <td class="text-center">
                                <a
                                    href="javascript:void(0)"
                                    @click="openModalAccept(data.id)"
                                    v-if="data.status == 'pending'"
                                    class="me-1"
                                >
                                    <img
                                        src="../../assets/check-green.png"
                                        width="16"
                                        alt=""
                                    />
                                </a>
                                <a
                                    href="javascript:void(0)"
                                    @click="openModalReject(data.id)"
                                    v-if="data.status == 'pending'"
                                >
                                    <img
                                        src="../../assets/close-red.png"
                                        width="16"
                                        alt=""
                                    />
                                </a>
                                <img
                                    src="../../assets/check-gray.png"
                                    width="16"
                                    alt=""
                                    class="me-1"
                                    v-if="data.status != 'pending'"
                                />
                                <img
                                    src="../../assets/close-gray.png"
                                    width="16"
                                    alt=""
                                    v-if="data.status != 'pending'"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="text-center mt-5" v-if="list.list_data.length == 0">
                    <img src="../../assets/no-data.png" width="100" alt="" />
                    <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                        Data Kosong
                    </h4>
                </div>
            </div>
            <div class="mt-4">
                <b-pagination-nav
                    v-model="currentPage"
                    v-if="list.list_data.length > 0"
                    :number-of-pages="list.total_page"
                    base-url="#"
                    first-number
                    align="right"
                    @input="getList"
                ></b-pagination-nav>
            </div>
        </div>

        <!-- MODAL -->
        <div class="modal-vue">
            <div class="overlay" v-if="modalAccept"></div>
            <div class="modal-body-chat vsm" v-if="modalAccept">
                <div class="mt-4 text-center">
                    <img src="../../assets/info-yellow.png" width="80" alt="" />
                    <h4 class="mb-0 fw-bold mt-4">
                        Pengajuan Branding Akan Diverifikasi
                    </h4>
                    <div class="text-gray-dashboard">
                        Pastikan informasi driver dan armada sudah sesuai
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="modalAccept = false"
                            class="btn btn-outline-red w-100 btn-lg"
                        >
                            Batal
                        </a>
                    </div>
                    <div class="col-md-6 mt-4">
                        <button
                            @click="acceptData()"
                            type="button"
                            class="btn btn-green w-100 btn-lg"
                            :disabled="is_accept"
                        >
                            <span
                                class="spinner-border spinner-border-sm me-2"
                                v-if="is_accept"
                            ></span>
                            Ya
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccessAccept"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccessAccept">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">
                        Pengajuan Branding Telah Diverifikasi
                    </h4>
                </div>
                <div class="mt-4">
                    <a
                        href="javascript:void(0)"
                        @click="(modalSuccessAccept = false), getList()"
                        class="btn btn-green w-100 btn-lg"
                    >
                        Ok
                    </a>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalReject"></div>
            <div class="modal-body-chat vsm" v-if="modalReject">
                <div class="mt-4 text-center">
                    <img src="../../assets/info-yellow.png" width="80" alt="" />
                    <h4 class="mb-0 fw-bold mt-4">
                        Pengajuan Branding Akan Ditolak!
                    </h4>
                    <div class="text-gray-dashboard">
                        Mohon lengkapi checklist sheet dan berikan alasan
                        penolakan
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="(modalReject = false), (reject_id = null)"
                            class="btn btn-outline-red w-100 btn-lg"
                        >
                            Batal
                        </a>
                    </div>
                    <div class="col-md-6 mt-4">
                        <a
                            :href="`/truck/sticker/reject/` + reject_id"
                            class="btn btn-green w-100 btn-lg"
                        >
                            Ya
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalRejectHistory"></div>
            <div class="modal-body-chat vsm" v-if="modalRejectHistory">
                <h5 class="mb-0 fw-bold">Alasan Penolakan</h5>
                <div class="history-tl-container mt-2">
                    <ul class="timeline timeline-split mt-4">
                        <template v-for="(item, index) in reject_history">
                            <li class="timeline-item" :key="index">
                                <div
                                    class="timeline-marker"
                                    v-bind:class="{
                                        active: index == 0,
                                    }"
                                ></div>
                                <div class="timeline-content">
                                    <div class="timeline-title">
                                        <span
                                            v-bind:class="{
                                                'text-dark': index == 0,
                                            }"
                                        >
                                            <template v-if="index == 0"
                                                >Penolakan Pertama</template
                                            >
                                            <template v-else
                                                >Penolakan Kedua</template
                                            >
                                        </span>
                                        -
                                        <span
                                            v-bind:class="{
                                                'text-primary': index == 0,
                                            }"
                                            >{{
                                                moment(item.rejected_at).format(
                                                    "DD MMM YYYY | hh:mm"
                                                )
                                            }}</span
                                        >
                                    </div>
                                    <div
                                        class="box reject no-shadow p-2 mt-1 fw-semibold"
                                    >
                                        {{ item.note }}
                                    </div>
                                </div>
                            </li>
                        </template>
                    </ul>
                </div>
                <div>
                    <button
                        class="btn btn-green w-100"
                        @click="modalRejectHistory = false"
                    >
                        Tutup
                    </button>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalExport">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img src="../../assets/info-yellow.png" width="80" alt="" />
                    <h4 class="mb-0 fw-bold mt-4">Data Akan Diekspor</h4>
                    <div class="text-gray-dashboard">
                        Apakah Anda yakin ingin mengekspor data ini?
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            class="btn btn-outline-red w-100 btn-lg"
                            @click="modalExport = false"
                        >
                            Batal
                        </a>
                    </div>
                    <div class="col-md-6 mt-4">
                        <button
                            type="button"
                            class="btn btn-green w-100 btn-lg"
                            @click="exportData()"
                        >
                            <span
                                v-if="isExport"
                                class="spinner-border spinner-border-sm me-2"
                            ></span>
                            Iya
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalExportSuccess">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">Data Berhasil Diekspor</h4>
                </div>
                <div class="mt-4">
                    <a
                        href="javascript:void(0)"
                        class="btn btn-green w-100 btn-lg"
                        @click="modalExportSuccess = false"
                    >
                        OK
                    </a>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalExportFilter">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img src="../../assets/info-yellow.png" width="80" alt="" />
                    <h4 class="mb-0 fw-bold mt-4">
                        Data Hasil Filter Akan Diekspor
                    </h4>
                    <div class="text-gray-dashboard">
                        Apakah Anda yakin ingin mengekspor data ini?
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            class="btn btn-outline-red w-100 btn-lg"
                            @click="modalExportFilter = false"
                        >
                            Batal
                        </a>
                    </div>
                    <div class="col-md-6 mt-4">
                        <button
                            type="button"
                            class="btn btn-green w-100 btn-lg"
                            @click="exportData()"
                        >
                            <span
                                v-if="isExport"
                                class="spinner-border spinner-border-sm me-2"
                            ></span>
                            Iya
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import ListLoader from "../../components/ListLoader.vue";
import moment from "moment";
import { Tooltip } from "bootstrap";
import DatePicker from "vue2-datepicker";

export default {
    components: {
        ListLoader,
        DatePicker,
    },
    name: "TruckSticker",
    data() {
        return {
            showFilter: false,
            registerDate: null,
            selectedFleet: [],
            fleets: [],
            selectedStatus: [],
            statuses: [
                { name: "Belum Terverifikasi", id: "pending" },
                { name: "Terverifikasi", id: "accept" },
                { name: "Banned", id: "reject" },
            ],
            moment: moment,
            search: "",
            is_list_ready: false,
            list: {
                list_data: [],
                next_page: 0,
                total_data: 0,
                total_page: 0,
                current_page: 0,
            },
            limit: 10,
            currentPage: 0,
            modalReject: false,
            reject_id: null,
            modalAccept: false,
            accept_id: null,
            is_accept: false,
            modalSuccessAccept: false,
            modalRejectHistory: false,
            reject_history: [],
            isExport: false,
            modalExport: false,
            modalExportFilter: false,
            modalExportSuccess: false,
        };
    },
    created() {
        this.getList();
        this.getFleets();
        new Tooltip(document.body, {
            selector: "[data-bs-toggle='tooltip']",
        });
    },
    computed: {
        dataDisplayRange() {
            if (this.list.list_data.length === 0) {
                return {
                    start: 0,
                    end: 0,
                    total: 0,
                };
            }

            const currentPageAdjusted =
                this.currentPage === 0 ? 1 : this.currentPage;
            const start = (currentPageAdjusted - 1) * this.limit + 1;
            const end = Math.min(
                start + this.list.list_data.length - 1,
                this.list.total_data
            );

            return {
                start,
                end,
                total: this.list.total_data,
            };
        },
    },
    methods: {
        exportModal() {
            if (this.registerDate || this.selectedFleet.length !== 0) {
                this.modalExportFilter = true;
            } else {
                this.modalExport = true;
            }
        },
        exportData() {
            this.isExport = true;
            let payload = {
                search: this.search,
                limit: this.limit,
                page:
                    this.currentPage == 0 || this.currentPage == null
                        ? 0
                        : this.currentPage - 1,
            };
            if (this.registerDate) {
                payload.date_range = `${moment(this.registerDate[0]).format(
                    "YYYY-MM-DD"
                )} - ${moment(this.registerDate[1]).format("YYYY-MM-DD")}`;
            }
            if (this.selectedFleet.length !== 0) {
                payload.fleet_types = this.selectedFleet
                    .map((s) => s.code)
                    .join(",");
            }
            if (this.selectedStatus.length !== 0) {
                payload.status = this.selectedStatus.map((s) => s.id).join(",");
            }
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/truck/head-office/branding/export/excel`,
                "",
                {
                    params: payload,
                    responseType: "blob",
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.data_excel_request = res.data;
                    const url = window.URL.createObjectURL(
                        new Blob([res.data])
                    );
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute(
                        "download",
                        "Daftar Branding Sticker" +
                            " " +
                            moment().format("DD MMM YYYY") +
                            `.xls`
                    );
                    document.body.appendChild(link);
                    link.click();
                    this.isExport = false;
                    if (this.registerDate || this.selectedFleet.length !== 0) {
                        this.modalExportFilter = true;
                        this.modalExportSuccess = true;
                    } else {
                        this.modalExport = false;
                        this.modalExportSuccess = true;
                    }
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                    this.isExport = false;
                    this.modalExport = false;
                    this.modalExportFilter = false;
                });
        },
        resetFilter() {
            this.registerDate = null;
            this.selectedFleet = [];
            this.currentPage = 0;
            this.getList();
        },
        applyFilter() {
            this.currentPage = 0;
            this.getList();
        },
        toggleFilter() {
            this.showFilter = !this.showFilter;
        },
        getList() {
            this.is_list_ready = true;
            let payload = {
                search: this.search,
                limit: this.limit,
                page:
                    this.currentPage == 0 || this.currentPage == null
                        ? 0
                        : this.currentPage - 1,
            };
            if (this.registerDate) {
                payload.date_range = `${moment(this.registerDate[0]).format(
                    "YYYY-MM-DD"
                )} - ${moment(this.registerDate[1]).format("YYYY-MM-DD")}`;
            }
            if (this.selectedFleet.length !== 0) {
                payload.fleet_types = this.selectedFleet
                    .map((s) => s.code)
                    .join(",");
            }
            if (this.selectedStatus.length !== 0) {
                payload.status = this.selectedStatus.map((s) => s.id).join(",");
            }
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/truck/head-office/branding`,
                {
                    params: payload,
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.list = data;
                    this.is_list_ready = false;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                    this.is_list_ready = false;
                });
        },
        getFleets() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/master/trawltruck-fleet`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.fleets = data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        changeSearch() {
            this.currentPage = 0;
            this.getList();
        },
        openModalReject(id) {
            this.reject_id = id;
            this.modalReject = true;
        },
        openModalAccept(id) {
            this.accept_id = id;
            this.modalAccept = true;
        },
        acceptData() {
            var data = {
                brand_id: this.accept_id,
            };
            this.is_accept = true;
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/truck/head-office/branding/verification`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    console.log(res);
                    this.is_accept = false;
                    this.modalAccept = false;
                    this.modalSuccessAccept = true;
                })
                .catch((err) => {
                    this.is_accept = false;
                    this.modalAccept = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        openModalRejectHistory(data) {
            this.reject_history = data.reject_history;
            this.modalRejectHistory = true;
        },
    },
};
</script>

<style scoped>
.fleet-type-button {
    background-color: #d9d9d9;
    color: #61616a;
}
.fleet-type-button:hover {
    background-color: #d9d9d9;
    color: #61616a;
}
.btn-outline-yellow {
    color: #fb9727;
    background-color: transparent;
    border: 1px solid #fb9727;
}
.btn-outline-yellow:hover {
    color: #fb9727;
    background-color: transparent;
    border: 1px solid #fb9727;
}
.text-link {
    color: #3c87b1 !important;
}
.btn-clear-disabled {
    border: 1px solid #d2d2d5 !important;
    color: #d2d2d5 !important;
}
.btn-filter {
    border: 1px solid #d9d9d9;
}
.btn-filter:hover {
    border: 1px solid #d9d9d9;
}
.btn-filter-disabled {
    background-color: #d2d2d5 !important;
    color: white !important;
    border: none !important;
}
.bg-row {
    background-color: #f6faff;
}
.dropdown-menu.show {
    width: 93%;
}
.dropdown-toggle::after {
    display: none;
}
.arrow-custom::before {
    position: relative;
    right: 8px;
    top: 12px;
    border-color: #999 transparent transparent;
    border-style: solid;
    border-width: 5px 5px 0;
    content: "";
}
div::-webkit-scrollbar {
    display: none;
}
.radius-select {
    border: 1px solid #e9ecef !important;
    padding: 0px 7px 0 7px !important;
    min-height: 35px !important;
    border-radius: 5px;
}
.scroll-select {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
}
.scroll-select .placeholder-custom {
    margin-bottom: 0;
    color: #6c757d;
}
.dropdown-menu {
    padding: 5px 10px 0px 10px;
}
.form-check-input:checked {
    background-color: #329a5f;
    border: #329a5f;
}
th {
    border-color: #e8e8e9;
    padding: 10px !important;
}
tr {
    border-color: #e8e8e9;
    padding: 10px !important;
}
td {
    padding: 10px !important;
}
.table-light {
    background-color: #f6faff !important;
    --bs-table-border-color: #f6faff !important;
    --bs-table-bg: #f6faff !important;
}
.box.reject {
    border: 1px solid #d9d9d9;
    background: #f6faff;
    border-radius: 8px;
}
.timeline-marker.active:before {
    background: #e60013;
}
.timeline-marker:after {
    background: #d9d9d9;
}
.timeline-marker:before {
    background: #f7aab0;
}
</style>
